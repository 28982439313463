<div class="container">
  <h1 class="header">{{ "ui.new-message" | translate }}</h1>

  <h2 class="header">{{ "ui.table.receiver" | translate }}</h2>

  <div class="selection-container">
    <div class="spacer"></div>
    <mat-form-field>
      <mat-label>{{ "ui.new-vehicle" | translate }}</mat-label>
      <input matInput [(ngModel)]="searchCraft" (keyup)="filterCrafts()">
    <mat-select [formControl]="SelectedCrafts" multiple (selectionChange)="clearCraftSearch()">
      <mat-option *ngFor="let craft of filteredCrafts" [value]="craft">
        {{ craft.craftNr }}
      </mat-option>
    </mat-select>
  </mat-form-field>
    
    <div class="spacer"></div>

    <mat-form-field>
      <mat-label>{{ "ui.new-employees" | translate }}</mat-label>
      <input matInput [(ngModel)]="searchDriver" (keyup)="filterDrivers()">
    <mat-select [formControl]="SelectedDrivers" multiple (selectionChange)="clearDriverSearch()">
      <mat-option *ngFor="let driver of filteredDrivers" [value]="driver">
        {{ driver.fullName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
    <div class="spacer"></div>
  </div>

  <form [formGroup]="regForm">
    <mat-form-field class="full-width">
      <mat-label>{{ "ui.table.message" | translate }}</mat-label>
      <input matInput #message type="text" id="messageText" formControlName="messageText" />
    </mat-form-field>
  </form>

  <div class="right">
    <mat-spinner *ngIf="sending" [diameter]="20"></mat-spinner>
    <button mat-icon-button (click)="sendMessage()" matTooltip="Senden" [disabled]="sending">
      <mat-icon *ngIf="!sending">done</mat-icon>
    </button>

    <button color="warn" routerLink="/messages" mat-icon-button matTooltip="Abbrechen">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>